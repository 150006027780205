import React from 'react'
import { FormikProvider, useFormik } from 'formik'

import { validationSchema } from '../ModalHelpers'
import styles from '../ItemModal.module.scss'

import { EditItemModalProps } from './EditItemModal.types'
import { useAuthState, useMutation } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import { Button, FormField, Spacer, TextArea } from '@percent/lemonade'
import { FieldInputCurrency, Loader } from '@percent/cause-dashboard/common/components'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { PatchMilestonesItemProps } from '@percent/cause-dashboard/services/milestones/milestonesService.types'
import { floatToIntegerCurrency } from '@percent/cause-dashboard/common/utility/utility'
import { Typography } from '@material-ui/core'
import getSymbolFromCurrency from 'currency-symbol-map'
import { TypeOf } from 'yup'

export function EditItemModal({ onClose, itemId, itemAmount, itemReward, cancel, save, refresh }: EditItemModalProps) {
  const {
    authState: { user, organisation }
  } = useAuthState()
  const { milestonesService } = useServices()
  const [{ isLoading }, { apiFunc: patchMoneyMilestonesItem }] = useMutation(
    milestonesService.patchMilestonesItem,
    () => {
      save(true)
      if (refresh) {
        onClose()
        refresh()
      }
    },
    () => {
      save(false)
    }
  )
  const { t } = useTranslation()
  const handleCancelForm = () => {
    resetForm()
    cancel()
  }

  const formik = useFormik({
    initialValues: {
      amount: (itemAmount / 100).toFixed(2),
      reward: itemReward
    },
    validationSchema,
    onSubmit: submitValues => {
      const { amount: inputAmount, reward } = submitValues as unknown as TypeOf<typeof validationSchema>

      const amount = floatToIntegerCurrency(inputAmount)
      patchMoneyMilestonesItem(buildShoppingListItem(user?.organisationId as string, amount, reward))
    }
  })

  const { errors, values, touched, handleChange, handleBlur, handleSubmit, resetForm, setFieldValue } = formik

  const buildShoppingListItem = (orgId: string, amount: number, reward: string): PatchMilestonesItemProps => {
    return {
      organisationId: orgId,
      id: itemId,
      amount,
      reward
    }
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className={styles.container}>
      <Typography className={styles.text}>
        {t('dialog.moneyMilestone.helperTextOne')}
        {getSymbolFromCurrency(organisation?.currencyCode || 'USD')}
        {t('dialog.moneyMilestone.helperTextTwo')}
      </Typography>
      <form onSubmit={handleSubmit}>
        <FieldInputCurrency
          name="itemAmount"
          label={t('form.itemAmount')}
          placeHolder={t('form.itemAmountPlaceholder')}
          value={values.amount}
          onChange={(a: string) => setFieldValue('amount', a)}
          handleBlur={handleBlur}
          error={errors.amount}
          addOn={organisation?.currencyCode}
          touched={touched.amount}
          isRequiredField
        />
        <FormikProvider value={formik}>
          <FormField
            label={t('form.milestoneReward')}
            status={touched.reward && errors.reward ? 'danger' : 'default'}
            statusMessage={errors.reward}
            data-testid="itemReward"
            necessity="required"
          >
            <TextArea
              name="reward"
              maxLength={255}
              placeholder={t('form.itemRewardPlaceholder')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.reward}
            />
          </FormField>
        </FormikProvider>
        <Spacer axis="vertical" size={5} />
        <div className={styles.buttonContainer}>
          <Button variant="secondary" data-testid="edit-cancel-button" onPress={handleCancelForm}>
            {t('button.cancel')}
          </Button>
          <Spacer size={2} />
          <Button type="submit" data-testid="edit-save-item-button">
            {t('button.saveChanges')}
          </Button>
        </div>
      </form>
    </div>
  )
}
