import React from 'react'
import { Grid, Typography } from '@material-ui/core'

import styles from './ModalContainer.module.scss'
import { ModalDeleteBodyProps } from './Modal.types'

import { useTranslation } from 'react-i18next'
import { Button, Spacer } from '@percent/lemonade'
import { Loader, ErrorView } from '@percent/cause-dashboard/common/components'

export function ModalDeleteBody({
  handleDelete,
  handleCancel,
  testId,
  isLoading,
  errorMessage,
  children
}: ModalDeleteBodyProps) {
  const { t } = useTranslation()

  if (isLoading) {
    return <Loader />
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <div className={styles.dialogMargin}>
      <Typography variant="body1" className={styles.bodyText}>
        {children}
      </Typography>
      <Grid item xs={12}>
        <div className={styles.buttonContainer}>
          <Button variant="secondary" data-testid="cancel-button" onPress={handleCancel}>
            {t('button.cancel')}
          </Button>
          <Spacer size={2} />
          <Button data-testid={testId} type="submit" onPress={handleDelete}>
            {t('button.deleteMilestone')}
          </Button>
        </div>
      </Grid>
    </div>
  )
}
