import { useAuthDispatch, useAuthState, useMutation } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useQuery } from '@percent/cause-dashboard/common/hooks/useQuery/useQuery'
import { useState, useEffect } from 'react'
import { Loader, ErrorView } from '@percent/cause-dashboard/common/components'
import { SET_AUTHORISED } from '@percent/cause-dashboard/context/auth'
import { BankDetailsProps } from './BankDetails.types'
import { ProofBankSuccessModal } from './modal/ProofBankSuccessModal'
import { ProofBankErrorModal } from './modal/ProofBankErrorModal'
import { BankDetailsReview } from './bankDetailsReview/BankDetailsReview'
import { BankDetailsView, BankDetailsUploadView, useAddBankDetailsForm } from '@percent/domain/bank-details'
import { BankDetailStatus } from '@percent/cause-dashboard/services/bankDetails/bankDetails.types'
import { Text } from '@percent/lemonade'

export function BankDetails({ verifiedCauseOwner, organisationId, countryCode }: BankDetailsProps) {
  const { t } = useTranslation()
  const [selectedFile, setSelectedFile] = useState<File>()
  const [openProofBankSuccessModal, setOpenProofBankSuccessModal] = useState(false)
  const [openProofBankErrorModal, setOpenProofBankErrorModal] = useState(false)
  const [isWaitingReview, setIsWaitingReview] = useState<boolean>(false)
  const [isAfterReview, setIsAfterReview] = useState<boolean>(false)
  const { bankDetailsService } = useServices()
  const { authDispatch } = useAuthDispatch()
  const { authState } = useAuthState()
  const [{ data: bankDetailsData, isLoading, errorMessage }] = useQuery(bankDetailsService.getBankDetails, {})
  const handleOpenProofBankErrorModal = () => setOpenProofBankErrorModal(true)
  const handleCloseProofBankErrorModal = () => setOpenProofBankErrorModal(false)
  const handleCloseProofBankSuccessModal = () => {
    setOpenProofBankSuccessModal(false)
  }
  const handleOpenProofBankSuccessModal = () => {
    setIsWaitingReview(true)
    setOpenProofBankSuccessModal(true)
    authDispatch({
      type: SET_AUTHORISED,
      payload: {
        ...authState,
        orgBankDetails: {
          ...authState.orgBankDetails,
          status: BankDetailStatus.PENDING
        }
      }
    })
  }

  const [{ isLoading: isPostLoading }, { apiFunc }] = useMutation(
    bankDetailsService.postBankDetails,
    () => {
      handleOpenProofBankSuccessModal()
    },
    () => {
      handleOpenProofBankErrorModal()
    }
  )

  useEffect(() => {
    if (bankDetailsData?.status === BankDetailStatus.PENDING) {
      setIsWaitingReview(true)
    }

    if (bankDetailsData?.status === BankDetailStatus.APPROVED) {
      setIsAfterReview(true)
    }
  }, [bankDetailsData?.status])

  const bankDetailsStatusFlag = !isWaitingReview && !isAfterReview
  const disabledField = isWaitingReview || isAfterReview

  const translations = {
    helperBankAccountText: t('typography.helperBankAccountText'),
    proofBankAccount: t('typography.proofBankAccount'),
    uploadDocument: t('button.uploadDocument'),
    submitBankDocuments: t('button.submitBankDocuments')
  }

  const { formik, firstField, secondField } = useAddBankDetailsForm({
    bankDetailsData,
    t,
    apiFunc,
    selectedFile
  })

  if (isLoading) {
    return <Loader />
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  const fileFieldText =
    ((selectedFile || (bankDetailsData?.status !== BankDetailStatus.REJECTED && bankDetailsData?.previousFilename)) &&
      selectedFile?.name) ||
    bankDetailsData?.previousFilename

  return (
    <>
      <ProofBankSuccessModal
        openProofBankSuccessModal={openProofBankSuccessModal}
        handleCloseProofBankSuccessModal={handleCloseProofBankSuccessModal}
      />
      <ProofBankErrorModal
        openProofBankErrorModal={openProofBankErrorModal}
        handleCloseProofBankErrorModal={handleCloseProofBankErrorModal}
      />
      {isWaitingReview && (
        <BankDetailsReview
          reviewBadgeText={t('typography.waitingReviewBadge')}
          reviewText={t('typography.waitingReviewText')}
          reviewLinkText={t('typography.reachOutToUsLink')}
          variant="info"
        />
      )}
      {isAfterReview && (
        <BankDetailsReview
          reviewBadgeText={t('typography.bankDetailsStatusText', {
            status: 'Verified'
          })}
          reviewText={t('typography.afterReviewText')}
          reviewLinkText={t('typography.reachOutToUsLink')}
          variant="success"
        />
      )}

      {!isWaitingReview && bankDetailsData?.status === 'rejected' && (
        <BankDetailsReview
          variant="error"
          reviewBadgeText={t('typography.bankDetailsStatusText', {
            status: 'Rejected'
          })}
        />
      )}

      {bankDetailsStatusFlag && <Text size="small">{t('typography.donationText')}</Text>}
      {bankDetailsData?.requiredBankAccountDetails ? (
        <BankDetailsView
          setSelectedFile={setSelectedFile}
          buttonFlag={bankDetailsStatusFlag}
          uploadButtonFlag={bankDetailsStatusFlag}
          disableUploadBtn={!verifiedCauseOwner}
          disabledField={disabledField}
          bankDetailsData={bankDetailsData}
          selectedFile={selectedFile}
          isPostLoading={isPostLoading}
          fileFieldText={fileFieldText}
          translations={translations}
          formik={formik}
          firstField={firstField}
          secondField={secondField}
        />
      ) : (
        <BankDetailsUploadView
          setSelectedFile={setSelectedFile}
          buttonFlag={bankDetailsStatusFlag}
          uploadButtonFlag={bankDetailsStatusFlag}
          disableUploadBtn={!verifiedCauseOwner}
          disabledField={disabledField}
          bankDetailsData={bankDetailsData}
          selectedFile={selectedFile}
          apiFunc={apiFunc}
          isPostLoading={isPostLoading}
          fileFieldText={fileFieldText}
          translations={translations}
        />
      )}
    </>
  )
}
