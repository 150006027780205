import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from '@material-ui/core'
import React, { useState } from 'react'
import getSymbolFromCurrency from 'currency-symbol-map'

import { DeleteItemModal } from '../modals/deleteItem/DeleteItemModal'
import { EditItemModal } from '../modals/editItem/EditItemModal'
import {
  ModalContainer,
  NoResultTable,
  MenuListComposition,
  PaginationActions,
  Loader,
  ErrorView
} from '@percent/cause-dashboard/common/components'
import { AcknowledgeModal, Alert, Button, Spacer } from '@percent/lemonade'
import styles from './MilestonesTable.module.scss'
import { useTranslation } from 'react-i18next'
import { MilestonesTableProps } from './MilestonesTable.types'
import { MilestonesItem } from '@percent/cause-dashboard/services/milestones/milestonesService.types'
import { formatMoney } from '@percent/cause-dashboard/common/utility/utility'
import EmptyTableImage from '@percent/cause-dashboard/common/assets/images/no-shopping-items.svg'
import { ResultStatus } from '../ResultStatus'

export function MilestonesTable({
  data,
  isLoading,
  errorMessage,
  totalResults,
  addItem,
  refresh,
  nextPage,
  previousPage,
  noMilestoneInLocation,
  currencyCode
}: MilestonesTableProps) {
  const { t } = useTranslation()
  const [editItem, setEditItem] = useState<MilestonesItem | undefined>(undefined)
  const [editSaveSuccess, setEditSaveSuccess] = useState<ResultStatus | undefined>(undefined)
  const [openEditItemModal, setOpenEditItemModal] = useState(false)
  const [openEditAcknowledgeModal, setOpenEditAcknowledgeModal] = useState(false)

  const [deleteSuccess, setDeleteSuccess] = useState<ResultStatus | undefined>(undefined)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openDeleteAcknowledgeModal, setOpenDeleteAcknowledgeModal] = useState(false)

  const [shoppingListItemId, setShoppingListItemId] = useState({
    sId: '',
    reward: ''
  })

  const handleAddItem = () => {
    addItem()
  }

  const handleEditItemOpen = (id?: string) => () => {
    setOpenEditItemModal(true)
    setEditItem(data?.find((item: MilestonesItem) => item.id === id))
  }

  const handleEditItemClose = () => {
    setOpenEditItemModal(false)
  }

  const handleEditItemSave = (success: boolean) => {
    setOpenEditItemModal(false)
    setEditSaveSuccess(success ? ResultStatus.Succeeded : ResultStatus.Failed)
    setOpenEditAcknowledgeModal(true)
  }

  const handleCloseEditAcknowledgeModal = () => {
    setOpenEditAcknowledgeModal(false)
  }

  const handleOpenDeleteModal = () => setOpenDeleteModal(true)
  const handleCloseDeleteModal = () => setOpenDeleteModal(false)

  const handleDeleteItem = (success: boolean) => {
    setDeleteSuccess(success ? ResultStatus.Succeeded : ResultStatus.Failed)
    setOpenDeleteAcknowledgeModal(true)
  }

  const handleOpenAcknowledgeDeleteModal = () => setOpenDeleteAcknowledgeModal(true)
  const handleCloseDeleteAcknowledgeModal = () => setOpenDeleteAcknowledgeModal(false)

  const rowShoppingListItemClicked = (sId: string, reward: string) => {
    setShoppingListItemId({
      sId,
      reward
    })
  }

  if (isLoading) {
    return <Loader />
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <Grid item>
      <TableContainer component={Paper}>
        {!noMilestoneInLocation && (
          <Alert
            variant="info"
            title={`${t('typography.milestonesInfoOne')}${getSymbolFromCurrency(currencyCode || 'USD')}${t(
              'typography.milestonesInfoTwo'
            )}`}
          >
            {t('typography.milestonesInfoThree')}
          </Alert>
        )}
        {totalResults !== 0 && (
          <>
            <Spacer size={4} axis="horizontal" />
            <ModalContainer
              openModal={openEditItemModal}
              onClose={handleEditItemClose}
              headerTitle={t('dialog.editMilestone.title')}
            >
              <EditItemModal
                onClose={handleEditItemClose}
                itemId={editItem ? editItem.id : ''}
                itemAmount={editItem ? editItem.amount : 0}
                itemReward={editItem?.reward || ''}
                cancel={handleEditItemClose}
                save={handleEditItemSave}
                refresh={refresh}
              />
            </ModalContainer>
            <ModalContainer openModal={openEditAcknowledgeModal} onClose={handleCloseEditAcknowledgeModal}>
              <AcknowledgeModal
                result={editSaveSuccess === ResultStatus.Succeeded ? 'positive' : 'negative'}
                title={
                  editSaveSuccess === ResultStatus.Succeeded
                    ? t('dialog.editMilestone.success')
                    : t('dialog.editMilestone.failure')
                }
                handleClose={handleCloseEditAcknowledgeModal}
                buttonText={t('button.done')}
              />
            </ModalContainer>
            <DeleteItemModal
              onClose={handleCloseDeleteModal}
              open={openDeleteModal}
              onDelete={handleDeleteItem}
              id={shoppingListItemId}
              refresh={refresh}
              handleOpenDeleteAcknowledgeModal={handleOpenAcknowledgeDeleteModal}
            />
            <ModalContainer openModal={openDeleteAcknowledgeModal} onClose={handleCloseDeleteAcknowledgeModal}>
              <AcknowledgeModal
                result={deleteSuccess === ResultStatus.Succeeded ? 'positive' : 'negative'}
                title={
                  deleteSuccess === ResultStatus.Succeeded
                    ? t('dialog.deleteMilestone.success')
                    : t('dialog.deleteMilestone.failure')
                }
                handleClose={handleCloseDeleteAcknowledgeModal}
                buttonText={t('button.done')}
              />
            </ModalContainer>
            <Toolbar className={styles.toolBar}>
              <Grid container justifyContent="space-between">
                <Typography variant="h6">{t('typography.allMilestones')}</Typography>
                {!noMilestoneInLocation && (
                  <Grid item>
                    <Button data-testid="container-add-item-button" onPress={handleAddItem}>
                      {t('button.addMilestone')}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Toolbar>
          </>
        )}
        {noMilestoneInLocation && (
          <NoResultTable image={EmptyTableImage} text={t('typography.noMilestonesInLocation')} />
        )}
        {totalResults === 0 && !noMilestoneInLocation && (
          <NoResultTable
            alertComponent={true}
            image={EmptyTableImage}
            text={t('typography.noMilestones')}
            actionsRow={
              <Button data-testid="add-shopping-list" type="submit" onPress={handleAddItem}>
                {t('button.addMilestone')}
              </Button>
            }
          />
        )}{' '}
        {(totalResults && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={styles.tableCellAmount}>{t('table.header.amount')}</TableCell>
                <TableCell>{t('table.header.canBeAchieved')}</TableCell>
                <TableCell className={styles.tableCellMenu}> {t('table.header.actions')} </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map(({ amount, reward, currencyCode, id }: MilestonesItem) => (
                <TableRow key={`donation-list-${id}`}>
                  <TableCell>{`${getSymbolFromCurrency(currencyCode)}${formatMoney(amount)}`}</TableCell>
                  <TableCell className={styles.reward}>{reward}</TableCell>
                  <TableCell className={styles.actionsCell} onClick={() => rowShoppingListItemClicked(id, reward)}>
                    <MenuListComposition
                      menuItemName={[
                        {
                          title: 'Edit',
                          openModal: handleEditItemOpen(id)
                        },
                        {
                          title: 'Delete',
                          openModal: handleOpenDeleteModal
                        }
                      ]}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell variant="footer" colSpan={5}>
                  <PaginationActions
                    count={totalResults}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    refresh={refresh}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        )) ||
          ''}
      </TableContainer>
    </Grid>
  )
}
