import React from 'react'

import { ModalContainer } from '@percent/cause-dashboard/common/components'
import { ProofBankSuccessModalProps } from './ProofBankSuccessModal.types'
import { useTranslation } from 'react-i18next'
import { AcknowledgeModal } from '@percent/lemonade'

export function ProofBankSuccessModal({
  openProofBankSuccessModal,
  handleCloseProofBankSuccessModal
}: ProofBankSuccessModalProps) {
  const { t } = useTranslation()

  return (
    <ModalContainer openModal={openProofBankSuccessModal} onClose={handleCloseProofBankSuccessModal}>
      <AcknowledgeModal
        title={t('dialog.proofBankSuccessModal.title')}
        description={t('dialog.proofBankSuccessModal.subTitle')}
        handleClose={handleCloseProofBankSuccessModal}
        result={'positive'}
        buttonText={t('button.close')}
        buttonTestId="modal-add-item-success-button"
      />
    </ModalContainer>
  )
}
