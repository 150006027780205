import React, { useState } from 'react'
import { Grid } from '@material-ui/core'

import { useAuthState, useQueryList } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { MilestonesTable } from './milestonesTable/MilestonesTable'
import { ModalContainer } from '@percent/cause-dashboard/common/components'
import { CreateItemModal } from './modals/createItem/CreateItemModal'
import { AcknowledgeModal } from '@percent/lemonade'
import { ResultStatus } from './ResultStatus'

const unSupportedLocation = ['GBR', 'USA', 'AUS']

export function MilestonesContainer() {
  const { t } = useTranslation()
  const {
    authState: { user, organisation }
  } = useAuthState()
  const { milestonesService } = useServices()
  const [{ data, isLoading, errorMessage, totalResults }, { refresh, refreshToFirstPage, nextPage, previousPage }] =
    useQueryList(milestonesService.getMoneyMilestones(user?.organisationId as string), {})
  const [open, setOpen] = useState(false)
  const [saveSuccess, setSaveSuccess] = useState<ResultStatus | undefined>(undefined)
  const [dialogContainerTitle, setDialogContainerTitle] = useState(`${t('dialog.addMilestone.title')}`)

  const handleDialogOpen = () => {
    setOpen(true)
  }

  const handleDialogClose = () => {
    setOpen(false)
    setSaveSuccess(undefined)
    setDialogContainerTitle(`${t('dialog.addMilestone.title')}`)
  }

  const handleSave = (success: boolean) => {
    if (success) {
      setSaveSuccess(ResultStatus.Succeeded)
      refreshToFirstPage()
      setDialogContainerTitle('')
    } else {
      setSaveSuccess(ResultStatus.Failed)
    }
  }

  const noMilestoneInLocation = !unSupportedLocation.includes(organisation?.countryCode || '')

  return (
    <Grid container spacing={3} direction="column" wrap="nowrap">
      <MilestonesTable
        data={data}
        isLoading={isLoading}
        errorMessage={errorMessage}
        totalResults={totalResults}
        addItem={handleDialogOpen}
        refresh={refresh}
        nextPage={nextPage}
        previousPage={previousPage}
        noMilestoneInLocation={noMilestoneInLocation}
        currencyCode={organisation?.currencyCode}
      />
      <ModalContainer openModal={open} onClose={handleDialogClose} headerTitle={dialogContainerTitle}>
        {saveSuccess === ResultStatus.Succeeded ? (
          <AcknowledgeModal
            result="positive"
            title={t('dialog.addMilestone.success')}
            handleClose={handleDialogClose}
            buttonText={t('button.done')}
          />
        ) : saveSuccess === ResultStatus.Failed ? (
          <AcknowledgeModal
            result="negative"
            title={t('dialog.addMilestone.failure')}
            handleClose={handleDialogClose}
            buttonText={t('button.done')}
          />
        ) : (
          <CreateItemModal cancel={handleDialogClose} save={handleSave} />
        )}
      </ModalContainer>
    </Grid>
  )
}
